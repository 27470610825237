import React, { memo, useEffect, useState } from 'react'
import Button from '../button/button'
import './styles.sass'

const Modal = props => {
    const [show, setShow] = useState(props.show)
    useEffect(() => {
        const t = setTimeout(
            () => {
                setShow(props.show)
            },
            props.show ? 0 : 550
        )
        return () => clearTimeout(t)
    }, [props.show])

    return (
        <div
            style={{ zIndex: '5' }}
            className={`tv-tool-modal disable-selecting ${props.className ? props.className : ''} ${
                props.show ? 'open' : ''
            }`}
        >
            {show ? (
                <>
                    <div className={'tv-tool-modal-headline'} data-cy={'headline'}>
                        {props.headline ?? 'Copy Past Forecast Columns'}
                    </div>

                    <div className={'tv-tool-modal-body'}>{props.children}</div>

                    <div className={'tv-tool-modal-bottom-buttons'}>
                        <Button
                            data-cy={'cancel-button'}
                            leftIcon={props.cancelIcon ?? 'clear'}
                            red
                            onClick={props.onCancel}
                        >
                            {props.cancelText ?? 'Cancel'}
                        </Button>
                        <Button
                            data-cy={'submit-button'}
                            loading={props.submitLoading}
                            leftIcon={props.submitIcon ?? 'check'}
                            green
                            onClick={props.onSubmit}
                            disabled={props.disabledSubmit ?? false}
                        >
                            {props.submitText ?? 'Submit'}
                        </Button>
                    </div>
                </>
            ) : null}
        </div>
    )
}

export default memo(Modal)
