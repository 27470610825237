import { useState, useEffect } from 'react'

let globalState = {}
let listeners = []
let actions = {}

const _useStore = (shouldListen = true) => {
    const setState = useState(globalState)[1]

    const dispatch = (actionIdentifier, payload) => {
        const newState = actions[actionIdentifier](globalState, payload)
        globalState = { ...globalState, ...newState }

        for (const listener of listeners) {
            listener(globalState)
        }
    }

    useEffect(() => {
        if (shouldListen) listeners.push(setState)

        return () => {
            if (shouldListen) listeners = listeners.filter(li => li !== setState)
        }
    }, [setState, shouldListen])

    return [globalState, dispatch]
}

export const useStore = () => {
    const state = _useStore()[0]
    const dispatch = _useStore(false)[1]
    return [state, dispatch]
}

export const initStore = (userActions, initialState) => {
    if (initialState) {
        globalState = { ...globalState, ...initialState }
    }
    actions = { ...actions, ...userActions }
}
