import { useEffect, useSyncExternalStore } from 'react'
import './styles/app.sass'
import Messages from './components/messages/messages'
import SelectOptions from './components/select/select-options/selectOptions'
import messagesHooksStore from './hooks-store/configs/messagesHooksStore'
import selectOptionsHooksStore from './hooks-store/configs/selectOptionsHooksStore'
import Theme from './_themes'
import Pages from './pages/pages'
import { useLocation } from 'react-router-dom'
import { useStore } from './hooks-store/store'
import { getFP } from './services/getFingerPrint'

messagesHooksStore()
selectOptionsHooksStore()

function App() {
    const [{ showLoadingSpinner, selectProps }, dispatch] = useStore()
    useEffect(() => {
        new Theme()
    }, [])

    const location = useLocation()

    return (
        <div className="powerbi-app">
            <Pages currentOpenPage={location.pathname} />
            <Messages />
            <SelectOptions />
            {Object.keys(selectProps).length > 1
                ? Object.keys(selectProps).map((_, selectOptionsIndex) =>
                      selectOptionsIndex !== 0 ? (
                          <SelectOptions key={String(selectOptionsIndex)} index={String(selectOptionsIndex)} />
                      ) : null
                  )
                : null}
            <div id={'outside-elements'} />
        </div>
    )
}

export default App
