import sha256 from 'crypto-js/sha256'
import hmacSHA512 from 'crypto-js/hmac-sha512'
import Base64 from 'crypto-js/enc-base64'

// this function is used to get user fingerprint to send the finger print to the be
// and check if the user try to hack the tool with his low tricks
export const getFP = () => {
    return btoa(
        JSON.stringify({
            userAgent: navigator.userAgent,
            language: navigator.language,
            platform: navigator.platform,
            screenWidth: window.screen.width,
            screenHeight: window.screen.height,
            colorDepth: window.screen.colorDepth,
            plugins: Array.from(navigator.plugins).map(function (plugin) {
                return {
                    name: plugin.name,
                    filename: plugin.filename,
                    description: plugin.description
                }
            })
        })
    )
}
