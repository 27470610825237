import './styles.sass'
import { useSearchParams } from 'react-router-dom'
import TextField from '../../components/textfield/textfield'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { customFetch } from '../../services/customFetch'
import Button from '../../components/button/button'
import Checkbox from '../../components/checkbox/checkbox'
import Logo from '../../components/logo/logo'

export const passwordRegex = (level, admin = false) => {
    const levels = {
        medium: 10,
        strong: 15
    }

    return new RegExp(`(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{${levels[level] + (admin ? 5 : 0)},})`)
}

const Login = ({ alertMessage, setCurrentPage, navigateAnimated, setUser }) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [resetPasswordAccessToken, setResetPasswordAccessToken] = useState(null)
    const [enabledResetPasswordAccessToken, setEnabledResetPasswordAccessToken] = useState(null)
    const [enabledResetPassword, setEnabledResetPassword] = useState(false)
    const [resetPasswordWaitingPeriod, setResetPasswordWaitingPeriod] = useState()

    // calc Waiting Period
    useEffect(() => {
        const i = setInterval(() => {
            const now = Date.now()
            const resetPasswordWaitingPeriod = localStorage.getItem('enableToResetPasswordAgainAfter')
            if (resetPasswordWaitingPeriod) {
                const _resetPasswordWaitingPeriodDateTime = new Date(resetPasswordWaitingPeriod)
                if (now <= _resetPasswordWaitingPeriodDateTime) {
                    const diffInMs = _resetPasswordWaitingPeriodDateTime - now // milliseconds between now & Christmas
                    const diffMins = Math.round(((diffInMs % 86400000) % 3600000) / 60000) // minutes
                    setResetPasswordWaitingPeriod(diffMins)
                } else {
                    setResetPasswordWaitingPeriod()
                    localStorage.removeItem('enableToResetPasswordAgainAfter')
                }
            }
        }, 50)

        return () => {
            clearInterval(i)
        }
    }, [])

    const [emailAddress, setEmailAddress] = useState('')
    const [showSecCodeForm, setShowSecCodeForm] = useState(false)
    const [secCode, setSecCode] = useState('')
    const [forgetPassword, setForgetPassword] = useState(false)
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [showSetNewPasswordForm, setShowSetNewPasswordForm] = useState(false)
    const [newPassword, setNewPassword] = useState('')
    const [newPasswordStrength, setNewPasswordStrength] = useState()
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('')
    const [accessToken, setAccessToken] = useState('')
    const [loggedIn, setLoggedIn] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)
    const [loggedInFirstTime, setLoggedInFirstTime] = useState(true)
    const firstTime = useRef({ username: true, password: true })
    const usernameTextfieldRef = useRef()

    const emailRegex = RegExp('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$')

    useEffect(() => {
        setCurrentPage('login')
    })

    const navigate = useNavigate()

    const checkAccessTokenMID = '773ssseeetsfasfdfasffe'
    // check if accessToken is not expired and login
    useEffect(() => {
        if (localStorage.getItem('accessToken'))
            customFetch({
                urlEnd: '/login',
                headers: {
                    checkTokenExpired: true
                },
                navigate: navigateAnimated,
                alertMessage: alertMessage,
                alertMessageId: checkAccessTokenMID,
                withoutNavigateToLogin: true,
                onLoad: (_data, success) => {
                    if (_data && _data.notExpired && success) {
                        setLoggedIn(true)
                        setLoggedInFirstTime(true)
                        setTimeout(() => {
                            navigate('/')
                        }, 2000)
                    }
                    setLoggedInFirstTime(false)
                }
            })
        if (usernameTextfieldRef.current) usernameTextfieldRef.current.focus()
    }, [usernameTextfieldRef])

    // mfa => multi factor authentication
    const login = async mfa => {
        customFetch({
            urlEnd: '/login',
            method: 'POST',
            headers: {
                ...(mfa
                    ? {
                          mfa: mfa,
                          securityCode: secCode,
                          mfaAccessToken: accessToken
                      }
                    : {
                          username: username,
                          password: password
                      })
            },
            withoutNavigateToLogin: true,
            navigate: navigateAnimated,
            alertMessage: alertMessage,
            alertMessageId: checkAccessTokenMID,
            onLoad: (_data, success) => {
                if (_data) {
                    if (success) {
                        if (Object.keys(_data).includes('oneTimeAccessToken')) {
                            setShowSetNewPasswordForm(true)
                            setAccessToken(_data['oneTimeAccessToken'])
                            alertMessage({
                                message: 'One-time password accepted, please set your new password now.',
                                status: 'success',
                                MID: checkAccessTokenMID,
                                dismissible: true
                            })
                            setIsAdmin(_data['userData']['isAdmin'])

                            console.log(_data)
                        } else if (Object.keys(_data).includes('mfa') && _data.mfa) {
                            setShowSecCodeForm(true)
                            setAccessToken(_data['mfaAccessToken'])
                            alertMessage({
                                message: 'We send a security code to your email address',
                                status: 'success',
                                MID: checkAccessTokenMID,
                                dismissible: true
                            })
                        } else {
                            setShowSetNewPasswordForm(false)
                            setAccessToken('')
                            // Save Data to localStorage
                            localStorage.setItem('accessToken', _data.accessToken)
                            localStorage.setItem('refreshToken', _data.refreshAccessToken)
                            localStorage.setItem('userData', JSON.stringify(_data.userData))
                            alertMessage({
                                message: `Welcome back ${_data.userData.firstName}`,
                                status: 'success',
                                MID: checkAccessTokenMID,
                                dismissible: false,
                                timeoutInMilliseconds: 10000
                            })
                            setUser(_data.userData)

                            setLoggedIn(true)
                            setLoggedInFirstTime(false)
                            setTimeout(() => {
                                navigate('/')
                            }, 2000)
                        }
                    } else if (Object.keys(_data).includes('expired')) {
                        setShowSecCodeForm(false)
                        setAccessToken()
                        setSecCode()
                    } else if (Object.keys(_data).includes('passwordExpired') && _data.passwordExpired) {
                        setForgetPassword(true)
                    }
                }
            }
        })
    }

    const setNewPasswordFunction = async () => {
        customFetch({
            urlEnd: '/user',
            method: 'PATCH',
            headers: {
                accessToken: accessToken,
                newPassword: newPassword
            },
            withoutNavigateToLogin: true,
            navigate: navigateAnimated,
            alertMessage: alertMessage,
            alertMessageId: checkAccessTokenMID,
            onLoad: (_data, success) => {
                if (_data && success) {
                    if (_data.status === 'success') {
                        alertMessage({
                            message: 'Password success changed, please login again.',
                            status: 'success',
                            MID: checkAccessTokenMID,
                            dismissible: true
                        })
                        setUsername('')
                        setPassword('')
                        setShowSetNewPasswordForm(false)
                        setAccessToken('')
                        setNewPassword('')
                        setNewPasswordConfirmation('')
                        setTimeout(() => {
                            if (usernameTextfieldRef.current) usernameTextfieldRef.current.focus()
                        }, 750)
                    }
                }
            }
        })
    }
    const requestResetPassword = async () => {
        customFetch({
            urlEnd: '/reset-password',
            method: 'POST',
            headers: {
                emailAddress: emailAddress
            },
            withoutNavigateToLogin: true,
            navigate: navigateAnimated,
            alertMessage: alertMessage,
            alertMessageId: checkAccessTokenMID,
            onLoad: (_data, success) => {
                if (_data) {
                    if (_data.status === 'success') {
                        alertMessage({
                            message: 'Email was success send.',
                            status: 'success',
                            MID: checkAccessTokenMID,
                            dismissible: true,
                            timeoutInMilliseconds: 3000
                        })

                        setForgetPassword(false)
                        setEmailAddress('')
                    } else if (
                        _data.status === 'danger' &&
                        Object.keys(_data).includes('enableToResetPasswordAgainAfter')
                    ) {
                        localStorage.setItem('enableToResetPasswordAgainAfter', _data.enableToResetPasswordAgainAfter)
                        setTimeout(() => {
                            setForgetPassword(false)
                            setEmailAddress('')
                        }, 3000)
                    }
                }
            }
        })
    }
    const checkResetPasswordAccessToken = async () => {
        customFetch({
            urlEnd: '/reset-password',
            method: 'GET',
            headers: {
                accessToken: resetPasswordAccessToken
            },
            withoutNavigateToLogin: true,
            navigate: navigateAnimated,
            alertMessage: alertMessage,
            alertMessageId: checkAccessTokenMID,
            onLoad: (_data, success) => {
                if (_data && success && _data.status === 'success' && _data.enabled) {
                    setIsAdmin(_data['userData']['isAdmin'])
                    setEnabledResetPassword(_data.enabled)
                    setEnabledResetPasswordAccessToken(_data.accessToken)
                    alertMessage({
                        message: 'Success! Set now your new password.',
                        status: 'success',
                        MID: checkAccessTokenMID,
                        dismissible: true,
                        timeoutInMilliseconds: 5000
                    })
                }
            }
        })
    }

    useEffect(() => {
        const _resetPasswordAccessToken = searchParams.get('resetPasswordAccessToken')
        if (_resetPasswordAccessToken) {
            setResetPasswordAccessToken(_resetPasswordAccessToken)
        }
    }, [searchParams])

    const resetPasswordTimeoutRef = useRef()
    useEffect(() => {
        if (resetPasswordAccessToken) {
            checkResetPasswordAccessToken()
        }
    }, [resetPasswordAccessToken])

    const resetPassword = async () => {
        customFetch({
            urlEnd: '/reset-password',
            method: 'PUT',
            headers: {
                accessToken: enabledResetPasswordAccessToken,
                password: newPassword
            },
            withoutNavigateToLogin: true,
            navigate: navigateAnimated,
            alertMessage: alertMessage,
            alertMessageId: checkAccessTokenMID,
            onLoad: (_data, success) => {
                if (_data && success && _data.status === 'success') {
                    if (searchParams.has('resetPasswordAccessToken')) {
                        searchParams.delete('resetPasswordAccessToken')
                        setSearchParams(searchParams)
                    }
                    setResetPasswordAccessToken()
                    setEnabledResetPassword()
                    setEnabledResetPasswordAccessToken()
                    setUsername('')
                    setPassword('')
                    setShowSetNewPasswordForm(false)
                    setAccessToken('')
                    setNewPassword('')
                    setNewPasswordConfirmation('')
                    setTimeout(() => {
                        if (usernameTextfieldRef.current) usernameTextfieldRef.current.focus()
                    }, 750)
                    alertMessage({
                        message: 'Success!. login to continue.',
                        status: 'success',
                        MID: checkAccessTokenMID,
                        dismissible: true,
                        timeoutInMilliseconds: 5000
                    })
                }
            }
        })
    }

    return (
        <div className={`powerbi-page login${loggedIn ? ' logged-in' : ''}`}>
            <div className={`login-container`}>
                <div className="headline">
                    <div className={'logo-container'}>
                        <Logo />
                    </div>
                    <p className={'header-text'}>
                        {showSetNewPasswordForm
                            ? 'Change your Password'
                            : forgetPassword
                              ? 'Reset your password'
                              : showSecCodeForm
                                ? 'Authenticate with security code'
                                : 'Login with your account data'}
                    </p>
                </div>
                <div className="login-content">
                    <div
                        className={`forms${
                            showSetNewPasswordForm || (enabledResetPassword && enabledResetPasswordAccessToken)
                                ? ' show-set-new-password-form'
                                : ''
                        }`}
                    >
                        <div
                            className={`horizontal-movable-forms${
                                forgetPassword && !showSetNewPasswordForm
                                    ? ' switch-to-right-form switch-to-forget-password-form'
                                    : ''
                            }${showSecCodeForm ? ' switch-to-right-form switch-to-sec-code-form' : ''}${
                                resetPasswordWaitingPeriod ? ' waiting-period' : ''
                            }`}
                        >
                            <div className="left-movable-form">
                                <div className={`form login-form`}>
                                    <div className={'text-fields'}>
                                        <TextField
                                            value={username ? username : ''}
                                            ref={usernameTextfieldRef}
                                            placeholder={'Benutzername'}
                                            onKeyDown={e => {
                                                if (e.key === 'Enter' && username && password) {
                                                    login()
                                                }
                                            }}
                                            onChange={_username => {
                                                if (firstTime.current.username) firstTime.current.username = false
                                                setUsername(_username)
                                            }}
                                            isInvalid={
                                                !username.replace(/\s+/g, '') && password && !firstTime.current.username
                                            }
                                            invalidText={'Bitte Benutzername eingeben'}
                                        />
                                        <hr />
                                        <TextField
                                            value={password ? password : ''}
                                            placeholder={'Passwort'}
                                            onKeyDown={e => {
                                                if (e.key === 'Enter' && username && password) {
                                                    login()
                                                }
                                            }}
                                            type={'password'}
                                            onChange={_password => {
                                                if (firstTime.current.password) firstTime.current.password = false
                                                setPassword(_password)
                                            }}
                                            isInvalid={username && !password && !firstTime.current.password}
                                            invalidText={'Bitte Passwort eingeben'}
                                        />
                                    </div>
                                    <div
                                        className="login-button"
                                        onClick={() => {
                                            if (username.replaceAll(' ', '') && password) {
                                                login()
                                            }
                                        }}
                                    >
                                        <span className="material-symbols-outlined"> navigate_next </span>
                                    </div>
                                </div>
                                <Button
                                    className="forget-password-button"
                                    onClick={() => {
                                        setForgetPassword(true)
                                    }}
                                    text
                                    red
                                >
                                    Forget your password?
                                </Button>
                            </div>
                            <div className="right-movable-form forget-password">
                                <div className="field">
                                    <div>
                                        <Button
                                            leftIcon={'arrow_back'}
                                            className={'back-to-login-form-button'}
                                            onClick={() => {
                                                setForgetPassword(false)
                                            }}
                                        />
                                        <div className={`form forget-password-form`}>
                                            <div className={'text-fields'}>
                                                <TextField
                                                    value={emailAddress ? emailAddress : ''}
                                                    placeholder={'Email address'}
                                                    autoComplete={'off'}
                                                    type={'email'}
                                                    isInvalid={!emailRegex.test(emailAddress)}
                                                    onChange={_email => {
                                                        setEmailAddress(_email)
                                                    }}
                                                />
                                            </div>
                                            <div
                                                className={`login-button${
                                                    !emailRegex.test(emailAddress) ? ' disabled' : ''
                                                }`}
                                                onClick={() => {
                                                    if (emailRegex.test(emailAddress)) requestResetPassword()
                                                }}
                                            >
                                                <span className="material-symbols-outlined"> check </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {resetPasswordWaitingPeriod ? (
                                    <h5 className={'reset-password-waiting-period'}>
                                        Try after {resetPasswordWaitingPeriod}{' '}
                                        {resetPasswordWaitingPeriod > 1 ? 'minutes' : 'minute'}
                                    </h5>
                                ) : null}
                            </div>
                            <div className="right-movable-form sec-code">
                                <div className="field">
                                    <div>
                                        <Button
                                            leftIcon={'arrow_back'}
                                            className={'back-to-login-form-button'}
                                            onClick={() => {
                                                setShowSecCodeForm(false)
                                                setSecCode()
                                            }}
                                        />
                                        <div className={`form forget-password-form`}>
                                            <div className={'text-fields'}>
                                                <TextField
                                                    value={secCode ? secCode : ''}
                                                    placeholder={'Enter the security code here'}
                                                    autoComplete={'off'}
                                                    type={'number'}
                                                    maxLength={6}
                                                    onChange={_secCode => {
                                                        setSecCode(_secCode)
                                                    }}
                                                />
                                            </div>
                                            <div
                                                className={`login-button${
                                                    secCode && secCode.length != 6 ? ' disabled' : ''
                                                }`}
                                                onClick={() => {
                                                    login(true)
                                                }}
                                            >
                                                <span className="material-symbols-outlined"> check </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`form set-new-password-form`}>
                            <div className={'text-fields'}>
                                <TextField
                                    value={newPassword ? newPassword : ''}
                                    className={newPasswordStrength}
                                    placeholder={'New password'}
                                    autoComplete={'off'}
                                    type={'password'}
                                    onChange={_password => {
                                        if (firstTime.current.password) firstTime.current.password = false
                                        setNewPassword(_password)
                                        if (passwordRegex('strong', isAdmin).test(_password))
                                            setNewPasswordStrength('Strong')
                                        else if (passwordRegex('medium', isAdmin).test(_password))
                                            setNewPasswordStrength('Medium')
                                        else setNewPasswordStrength('Weak')
                                    }}
                                    isInvalid={
                                        newPassword && ['Weak', 'Medium', 'Strong'].includes(newPasswordStrength)
                                    }
                                    invalidText={'Password strength: ' + newPasswordStrength}
                                />
                                <hr />
                                <TextField
                                    value={newPasswordConfirmation ? newPasswordConfirmation : ''}
                                    className={newPassword === newPasswordConfirmation ? 'matched' : ''}
                                    placeholder={'Confirm new password'}
                                    autoComplete={'off'}
                                    type={'password'}
                                    onChange={_password => {
                                        if (firstTime.current.password) firstTime.current.password = false
                                        setNewPasswordConfirmation(_password)
                                    }}
                                    isInvalid={newPassword}
                                    invalidText={
                                        newPassword !== newPasswordConfirmation
                                            ? 'Confirmation password not matched!'
                                            : 'Password confirmed'
                                    }
                                />
                            </div>
                            <div
                                className="login-button"
                                onClick={() => {
                                    if (
                                        newPassword.replaceAll(' ', '') &&
                                        (passwordRegex('strong', isAdmin).test(newPassword) ||
                                            passwordRegex('medium', isAdmin).test(newPassword)) &&
                                        newPassword === newPasswordConfirmation
                                    ) {
                                        if (enabledResetPassword && enabledResetPasswordAccessToken) {
                                            resetPassword()
                                        } else {
                                            setNewPasswordFunction()
                                        }
                                    }
                                }}
                            >
                                <span className="material-symbols-outlined"> check </span>
                            </div>
                        </div>
                        <div className="new-password-strength-hints">
                            <ul>
                                <li>
                                    <Checkbox checked={newPassword?.length >= (isAdmin ? 15 : 10)}>
                                        {isAdmin ? 15 : 10} characters at least
                                    </Checkbox>
                                </li>
                                <li>
                                    <Checkbox checked={newPassword?.match(/[A-Z]+/g)}>1x uppercase letter</Checkbox>
                                </li>
                                <li>
                                    <Checkbox checked={newPassword?.match(/\d+/g)}>1x number</Checkbox>
                                </li>
                                <li>
                                    <Checkbox checked={newPassword?.match(/[!@#$%^&*]+/g)}>
                                        1x special character
                                    </Checkbox>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {Boolean(1 === 2) && (
                <div className={`login-container sso-login`} title={'will be soon available'}>
                    <Button className={'sso-login-button'} primary blue disabled>
                        SSO Login
                    </Button>
                </div>
            )}
        </div>
    )
}

export default Login
