import { Outlet, Navigate, Route } from 'react-router-dom'

const PrivateRouteAuth = () => {
    const accessToken = localStorage.getItem('accessToken')

    return accessToken ? <Outlet /> : <Navigate to={'/login'} />
}

const PrivateRoute = ({ exact, path, Component, props, key }) => {
    return (
        <Route key={key} exact={exact} path={path} element={<PrivateRouteAuth />}>
            <Route exact={exact} path={path} element={<Component {...props} />} />
        </Route>
    )
}

export default PrivateRoute
