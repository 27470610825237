import React, { useState } from 'react'
import './styles.sass'

const Checkbox = ({
    checked,
    indeterminate = false,
    className,
    primary,
    disabled,
    children,
    onCheck,
    left,
    right,
    ...props
}) => {
    return (
        <div
            {...props}
            className={`checkbox disable-selecting ${className ?? ''}${primary ? ' primary' : ''}${
                disabled ? ' disabled' : ''
            }${!onCheck ? ' readonly' : ''}${checked ? ' checked' : ''}`}
            onClick={() => {
                if (onCheck) onCheck(!checked)
            }}
        >
            {right && !left ? <div className={'children right'}>{children}</div> : null}
            <span className={`checkbox-icon${checked ? ' checked' : ''} ${indeterminate ? ' indeterminate' : ''}`}>
                <span className={'material-symbols-outlined'}>{indeterminate ? 'remove' : 'check'}</span>
            </span>
            {children && (left || (!left && !right)) ? <div className={'children left'}>{children}</div> : null}
        </div>
    )
}

export default Checkbox
