import './styles.sass'
import { memo, useEffect, useRef, useState } from 'react'
import Modal from '../../../../components/modal/modal'
import { customFetch } from '../../../../services/customFetch'
import TextField from '../../../../components/textfield/textfield'
import Checkbox from '../../../../components/checkbox/checkbox'
import Select from '../../../../components/select/select'
import Button from '../../../../components/button/button'

const EditAccountModal = ({
    show,
    setShow,
    reload,
    reportIds,
    accountData,
    setCurrentPage,
    navigateAnimated,
    alertMessage
}) => {
    const [newAccountData, setNewAccountData] = useState({
        firstName: accountData?.firstName,
        lastName: accountData?.lastName,
        reportIds: accountData?.reportIds,
        isAdmin: accountData?.isAdmin,
        changePassword: false
    })
    const [oneTimePassword, setOneTimePassword] = useState()
    const [username, setUsername] = useState()

    useEffect(() => {
        if (accountData) {
            setNewAccountData({
                firstName: accountData?.firstName,
                lastName: accountData?.lastName,
                reportIds: accountData?.reportIds,
                isAdmin: accountData?.isAdmin,
                changePassword: false
            })
        }
    }, [accountData])

    const closeModal = reloadingData => {
        if (reloadingData) reload()
        setShow()
        setTimeout(() => {
            setNewAccountData({
                firstName: null,
                lastName: null,
                reportIds: null,
                isAdmin: false,
                changePassword: false
            })
            setOneTimePassword()
            setUsername()
        }, 550)
    }
    const editAccount = () => {
        customFetch({
            urlEnd: '/admin',
            method: 'PUT',
            headers: {
                userId: accountData.id
            },
            body: newAccountData,
            navigate: navigateAnimated,
            alertMessage: alertMessage,
            alertMessageId: '0baf5wd2-2905-4GR3-450f-86t7ee37dlc8',
            alertMessagesData: {
                loadingMessageText: 'Account is being created!',
                successMessageText: 'Account successfully created!',
                errorMessageText: 'Account cannot be created!'
            },
            onLoad: (_data, success) => {
                if (_data && _data.status && _data.status === 'success' && success) {
                    closeModal(true)
                }
            },
            onCatch: err => {
                console.log(err)
            }
        })
    }

    return (
        <Modal
            className={`edit-account-modal`}
            headline={'Edit account'}
            show={show}
            submitText={'Change'}
            submitIcon={'check'}
            disabledSubmit={
                !(newAccountData.firstName?.replaceAll(' ', '') || newAccountData.lastName?.replaceAll(' ', ''))
            }
            onSubmit={() => {
                if (newAccountData.firstName?.replaceAll(' ', '') && newAccountData.lastName?.replaceAll(' ', ''))
                    editAccount()
                else closeModal(true)
            }}
            submitLoading={false}
            cancelText={'Cancel'}
            cancelIcon={'clear'}
            onCancel={() => {
                setShow(false)
                closeModal()
            }}
        >
            <TextField
                placeholder={'Firstname'}
                value={newAccountData.firstName}
                isInvalid={!newAccountData.firstName?.replaceAll(' ', '')}
                onChange={_value => {
                    setNewAccountData(prevNewAccountData => ({
                        ...prevNewAccountData,
                        firstName: _value
                    }))
                }}
            />
            <TextField
                placeholder={'Lastname'}
                value={newAccountData.lastName}
                isInvalid={!newAccountData.lastName?.replaceAll(' ', '')}
                onChange={_value => {
                    setNewAccountData(prevNewAccountData => ({
                        ...prevNewAccountData,
                        lastName: _value
                    }))
                }}
            />
            {!newAccountData.isAdmin ? (
                <Select
                    options={reportIds ?? {}}
                    multiSelect
                    enableSearch
                    selected={
                        newAccountData.reportIds
                            ?.split(',')
                            .filter(Boolean)
                            .map(r => [r, '']) ?? []
                    }
                    onSelect={selectedReports => {
                        setNewAccountData(prevNewAccountData => ({
                            ...prevNewAccountData,
                            reportIds: selectedReports
                                .map(r => r[0])
                                .filter(Boolean)
                                .join(',')
                        }))
                    }}
                >
                    <Button leftIcon={'expand_more'}>
                        Selecte reports: {newAccountData.reportIds?.split(',').filter(Boolean).length ?? 0}
                    </Button>
                </Select>
            ) : null}
            <Checkbox
                checked={newAccountData.isAdmin}
                onCheck={() => {
                    setNewAccountData(prevNewAccountData => ({
                        ...prevNewAccountData,
                        isAdmin: !prevNewAccountData.isAdmin
                    }))
                }}
            >
                Admin
            </Checkbox>
            <Checkbox
                checked={newAccountData.changePassword}
                onCheck={() => {
                    setNewAccountData(prevNewAccountData => ({
                        ...prevNewAccountData,
                        changePassword: !prevNewAccountData.changePassword
                    }))
                }}
            >
                Change Password
            </Checkbox>
        </Modal>
    )
}
export default EditAccountModal
